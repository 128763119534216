<template>
    <div>
        <b-row>
            <b-col>
                <b-card>
                    <b-card-body>
                        <b-row>
                            <b-col>
                                <b-form-group label="ID">
                                    <b-form-input v-model="job.id" readonly></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-row>
                                <b-col>
                                    <b-form-group label="Created At">
                                        <b-form-input v-model="job.createdAt" readonly></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col>
                                    <b-form-group label="Updated At">
                                        <b-form-input v-model="job.updatedAt" readonly></b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-col>
                                <b-form-group label="Status">
                                    <b-form-input v-model="job.status" readonly></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-form-group label="Started At">
                                    <b-form-input v-model="job.startedAt" readonly></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col>
                                <b-form-group label="Finished At">
                                    <b-form-input v-model="job.finishedAt" readonly></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-form-group label="Details">
                                    <b-form-textarea v-model="job.details" readonly rows="20"></b-form-textarea>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { getJob } from "@/services/JobService";
import { BButton, BCard, BCardBody, BCol, BForm, BFormGroup, BFormInput, BFormTextarea, BRow } from 'bootstrap-vue';

export default {
    name: "Job",
    components: {
        BButton,
        BCard,
        BCardBody,
        BCol,
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BRow
    },
    data() {
        return {
            job: null
        }
    },
    async mounted() {
        this.job = await getJob(this.$route.params.id);
    },
}
</script>